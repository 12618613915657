import * as v from 'valibot';
import { DateTime } from 'luxon';

const form = {
  optional<S extends v.BaseSchema<any, any, any>>(schema: S) {
    return v.pipe(
      v.any(),
      v.transform((v) =>
        (typeof v === 'string' && v.length > 0) || (typeof v !== 'string' && v !== null && v !== undefined)
          ? v
          : undefined,
      ),
      v.optional(schema),
    );
  },
  datetime() {
    return v.custom<TDateISO>((input) => {
      if (typeof input !== 'string') {
        return false;
      }

      try {
        DateTime.fromISO(input);

        return true;
      } catch (e) {
        return false;
      }
    }, 'Not a valid date time.');
  },
  isoDate() {
    return v.custom<TDateISODate>((input) => {
      if (typeof input !== 'string') {
        return false;
      }

      try {
        DateTime.fromISO(input);

        return true;
      } catch (e) {
        return false;
      }
    });
  },
  date() {
    return v.pipe(
      form.datetime(),
      v.transform((v) => DateTime.fromISO(v).toJSDate()),
    );
  },
  phone(required = 'Please enter a phone number') {
    return v.pipe(
      v.string(required),
      v.transform((v) => (v.startsWith('+1') ? v : v.replace(/\D/g, ''))),
      v.check(
        (v) => v.match(/^\+1?\d{10}$/) !== null || v.match(/^\d{10}$/) !== null,
        'Please enter a 10 digit phone number with no spaces or dashes.',
      ),
      v.transform((v) => (v.startsWith('+1') ? v : `+1${v}`) as TPhone),
      v.check(
        (v) => v.match(/\+1\d{10}/) !== null,
        'Must be a valid phone number (starting with +1, eg: +19876543210).',
      ),
    );
  },
  number() {
    return v.pipe(
      v.union([v.string(), v.number()]),
      v.transform((value) =>
        typeof value === 'string' && value.match(/^[0-9]+(\.[0-9]+)?$/) ? parseFloat(value) : value,
      ),
      v.check((value) => typeof value === 'number' && !isNaN(value), 'Must be a number.'),
      v.transform((value) => value as number),
    );
  },
  boolean: v.pipe(
    v.union([v.boolean(), v.literal('true'), v.literal('false'), v.literal(''), v.undefined()]),
    v.transform((value) => value === true || value === 'true'),
  ),
  money({ positive = false }: { positive?: boolean } = {}) {
    const pipe = v.pipe(
      v.string('Please enter a dollar amount.'),
      v.check(
        (v) => v.match(/^-?\d+(\.\d{1,2})?$/) !== null,
        'Must be a valid dollar amount (eg: 19.99) without a currency sign.',
      ),
      v.transform((v) => v as TMoney),
    );

    if (positive) {
      return v.pipe(
        pipe,
        v.check((v) => parseFloat(v) > 0, 'Must be a positive dollar amount.'),
      );
    }

    return pipe;
  },
  email(required = 'Please enter an email address') {
    return v.pipe(
      v.string(required),
      v.trim(),
      v.toLowerCase(),
      v.email('The email address provided is not valid.'),
      v.transform((v) => v as TEmail),
    );
  },
  flexibleNumber() {
    return v.pipe(
      v.union([v.string(), v.number()]),
      v.transform((value) => {
        if (typeof value === 'string') {
          // strip out all non-numeric except period
          const cleaned = value.replace(/[^0-9.]/g, '');
          return cleaned.length ? parseFloat(cleaned) : 0;
        }
        return value;
      }),
      v.check((val) => typeof val === 'number' && !isNaN(val), 'Must be a number.'),
      v.transform((val) => val as number),
    );
  },
};

export default form;
